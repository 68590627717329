<template>
  <grading-form :course-id="courseId" :partner_code="partner_code" />
</template>

<script>
import GradingForm from "#ecf/grading/components/GradingForm";

export default {
  name: "GradingPage",
  components: { GradingForm },
  props: {
    courseId: {
      type: String,
      required: true
    },
    partner_code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showLetterGrading: true,
      grades: [],
      mode: "view"
    };
  },
  computed: {
    title() {
      if (this.mode === "view") return "Grading";
      else if (this.mode === "edit") return "Edit Grading";
      else if (this.mode === "create") return "Add Grading";
      return "Grading";
    }
  },
  created() {},
  methods: {}
};
</script>

<style scoped></style>
