var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("grading-form", {
    attrs: { "course-id": _vm.courseId, partner_code: _vm.partner_code },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }