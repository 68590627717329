<template>
  <v-container v-if="renderNow" class="mt-10">
    <PageTitle :page-title="!this.$language ? title : banglaTitle">
      <template #left>
        <v-btn
          class="mr-2"
          color="black"
          icon
          large
          @click="
            () => {
              $router.push({
                name: 'grading',
              });
            }
          "
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </template>
    </PageTitle>
    <div class="pt-2" style="width: 1100px">
      <submission-alert-message
        should-scroll-to-top
        id="banner"
        ref="banner"
        v-model="showAlertBanner"
        :message="badFormMessage"
      ></submission-alert-message>
    </div>
    <v-card width="1100" outlined flat class="mt-10">
      <div class="ml-10 mt-10">
        <v-row>
          <v-col v-if="!$language" cols="9"
            >Predefined data types are already added. Enter your weigh
            <b>Percentage</b> for each data type.
          </v-col>
          <v-col v-else cols="9"
            >ইতিমধ্যে ডেটা টাইপ যোগ করা হয়েছে। প্রতিটি ডেটার জন্য শতকরা মান টাইপ
            করুন।</v-col
          >
          <v-col cols="2">
            <div v-if="isOwner && !editable">
              <v-btn
                v-if="!$language"
                rounded
                outlined
                color="primary"
                width="160"
                @click="setEditableTrue()"
              >
                <v-icon>edit</v-icon>
                Edit
              </v-btn>
              <v-btn
                v-else
                rounded
                outlined
                color="primary"
                width="160"
                @click="setEditableTrue()"
              >
                <v-icon>edit</v-icon>
                এডিট
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div class="ml-2 mt-4" style="width: 430px">
          <v-row style="font-weight: bold">
            <v-col v-if="!$language" cols="6">Type</v-col>
            <v-col v-else cols="6">টাইপ</v-col>
            <v-col v-if="!$language" cols="6">Percentage (%)</v-col>
            <v-col v-else cols="6">শতকরা মান (%)</v-col>
          </v-row>
          <v-row class="mt-4" style="border-bottom: 1px solid #d0d0d0">
            <v-col v-if="!$language" cols="6">Assignments</v-col>
            <v-col v-else cols="6">অ্যাসাইনমেন্ট</v-col>
            <v-col cols="6"
              ><v-text-field
                v-model="formData.grading_weights.assignment_weight"
                outlined
                placeholder="Enter text here..."
                :disabled="!editing"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row class="mt-4" style="border-bottom: 1px solid #d0d0d0">
            <v-col v-if="!$language" cols="6">Project</v-col>
            <v-col v-else cols="6">প্রজেক্ট</v-col>
            <v-col cols="6"
              ><v-text-field
                v-model="formData.grading_weights.project_weight"
                outlined
                placeholder="Enter text here..."
                :disabled="!editing"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row class="mt-4" style="border-bottom: 1px solid #d0d0d0">
            <v-col v-if="!$language" cols="6">Exams</v-col>
            <v-col v-else cols="6">পরীক্ষা</v-col>
            <v-col cols="6"
              ><v-text-field
                v-model="formData.grading_weights.exam_weight"
                outlined
                placeholder="Enter text here..."
                :disabled="!editing"
              ></v-text-field
            ></v-col>
          </v-row>
          <span
            v-if="editing && weightSumNotValid"
            style="color: red; font-size: 14px"
          >
            <span v-if="!$language"
              >The summation of assignment, project and exam weight must be
              100.</span
            >
            <span v-else
              >অ্যাসাইনমেন্ট, প্রজেক্ট এবং পরীক্ষার মার্কসের সমষ্টি ১০০ হতে
              হবে।</span
            >
          </span>
        </div>
      </div>

      <div class="ml-10 mt-10">
        <h3 v-if="!$language">Letter Grading:</h3>
        <h3 v-else>লেটার গ্রেডিং:</h3>
        <div v-if="!$language">
          {{
            editing
              ? `Add Letter Grading ( for example A+ , A , A- etc.) for the final
                  grades of the students`
              : `This grading will be added based on final marks.`
          }}
        </div>
        <div v-else>
          {{
            editing
              ? `শিক্ষার্থীদের চূড়ান্ত গ্রেডের জন্য লেটার গ্রেডিং যোগ করুন (উদাহরণস্বরূপ A+, A, A- ইত্যাদি)`
              : `চূড়ান্ত নম্বরের ভিত্তিতে এই গ্রেডিং যোগ করা হবে।`
          }}
        </div>
        <span
          v-if="editing && !letterGradesOk()"
          style="color: red; font-size: 14px"
        >
          <span v-if="!$language">
            The letter grades must cover all the percentages and must not
            overlap.</span
          >
          <span v-else
            >লেটার গ্রেড অবশ্যই সমস্ত শতাংশ কভার করবে এবং ওভারল্যাপ করবে
            না।</span
          >
        </span>
        <div v-if="!showLetterGrading">
          <v-btn
            outlined
            color="primary"
            rounded
            @click="showLetterGrading = true"
            >Add Letter Grading</v-btn
          >
        </div>
        <div v-if="showLetterGrading" class="ml-2 mt-4" style="width: 500px">
          <v-row style="font-weight: bold">
            <v-col v-if="!$language" cols="5">Grade</v-col>
            <v-col v-else cols="5">গ্রেড</v-col>
            <v-col cols="2"></v-col>
            <v-col v-if="!$language" cols="5" style="text-align: left"
              >Limit (%) (lower to upper)</v-col
            >
            <v-col v-else cols="5" style="text-align: left"
              >লিমিট (%) (নিম্ন থেকে উচ্চ)</v-col
            >
          </v-row>
          <v-row
            v-for="(grade, i) in formData.letter_grades"
            :key="i"
            class="mt-4"
            style="border-bottom: 1px solid #d0d0d0"
          >
            <v-col cols="4" class="pa-0">
              <v-text-field
                v-model="formData.letter_grades[i].grade_name"
                outlined
                :placeholder="
                  !$language ? 'Enter grade here...' : 'এখানে গ্রেড লিখুন'
                "
                :disabled="!editing"
              />
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="5">
              <v-row>
                <v-select
                  v-model="formData.letter_grades[i].lowest_percentage"
                  style="width: 20px !important"
                  :items="zeroToHundred"
                  outlined
                  placeholder="00"
                  :disabled="!editing"
                  @change="letterGradesOk()"
                />
                <div style="align-content: center" class="pa-4">to</div>
                <!-- <div v-else style="align-content: center" class="pa-4">থেকে</div> -->
                <v-select
                  v-model="formData.letter_grades[i].highest_percentage"
                  style="width: 20px !important"
                  :items="zeroToHundred"
                  outlined
                  placeholder="00"
                  :disabled="!editing"
                  @change="letterGradesOk()"
                />
              </v-row>
            </v-col>
            <v-col cols="1"
              ><v-btn
                v-if="editing"
                icon
                color="#FF5252"
                @click="formData.letter_grades.splice(i, 1)"
                ><v-icon>close</v-icon></v-btn
              ></v-col
            >
          </v-row>
          <v-col>
            <v-btn
              v-if="editing"
              rounded
              text
              color="primary"
              style="text-transform: none"
              @click="addAnotherGrade"
              ><v-icon left>mdi-plus</v-icon>
              {{ !$language ? "Add another" : "আরেকটি যোগ করুন" }}
            </v-btn>
          </v-col>
        </div>
      </div>
      <div class="ml-10 mt-10">
        <h3 v-if="!$language">Pass Mark</h3>
        <h3 v-else>পাশ মার্ক</h3>
        <p v-if="!editing">{{ formData.pass_mark || "Not set yet" }}</p>
        <v-radio-group v-else v-model="radioValue">
          <v-radio
            :value="0"
            :label="
              !$language
                ? 'Use lowest range as pass mark (' + lowestHigh + ')'
                : 'পাস মার্ক হিসাবে সর্বনিম্ন পরিসর ব্যবহার করুন (' +
                  lowestHigh +
                  ')'
            "
          ></v-radio>
          <v-radio :value="1" v-if="!$language" label="Custom"></v-radio>
          <v-radio :value="1" v-else label="কাস্টম"></v-radio>
        </v-radio-group>
        <v-text-field
          v-if="radioValue === 1 && editing"
          v-model="formData.pass_mark"
          outlined
          :label="!$language ? 'Custom pass mark' : 'কাস্টম পাশ মার্ক'"
          :rules="rules.customPassMarks"
          style="width: 200px"
          @change="updatePass()"
        ></v-text-field>
      </div>
      <v-row v-if="editing" class="mt-4 mr-4">
        <v-col align="right">
          <v-btn
            rounded
            outlined
            color="red accent-3"
            width="143"
            @click="setEditableFalse()"
          >
            <v-icon left>cancel</v-icon>
            {{ !$language ? "Cancel" : " বাতিল করুন" }}
          </v-btn>
          <v-btn
            rounded
            depressed
            color="primary"
            width="143"
            class="ml-4"
            @click="saveGradingFormat()"
          >
            <v-icon left>save_alt</v-icon>
            {{ !$language ? "Save" : "সেভ করুন" }}
          </v-btn>
          <!-- :disabled="weightSumNotValid() || !letterGradesOk() || !passOk" -->
        </v-col>
      </v-row>
    </v-card>
  </v-container>
  <div v-else>
    <template>
      <div id="loading-wrapper">
        <div id="loading-text">LOADING</div>
        <div id="loading-content"></div>
      </div>
    </template>
  </div>
</template>

<script>
import gradingApi from "#ecf/grading/api";
import { cloneDeep } from "lodash";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "GradingForm",
  props: {
    courseId: {
      type: String,
      required: true,
    },
    partner_code: {
      type: String,
      required: true,
    },
  },
  components: { SubmissionAlertMessage },
  mixins: [FieldValidations],
  data() {
    return {
      renderNow: false,
      title: "",
      banglaTitle: "",
      gradingFormat: undefined,
      showLetterGrading: true,
      showAlertBanner: false,
      radioValue: 0,
      // val: undefined,
      formData: {
        course_id: this.courseId,
        partner_code: this.partner_code,
        pass_mark: this.pass_mark || 0,
        locked: false,
        grading_weights: {
          assignment_weight: undefined,
          project_weight: undefined,
          exam_weight: undefined,
        },
        letter_grades: [
          {
            lowest_percentage: undefined,
            grade_name: undefined,
            highest_percentage: undefined,
          },
        ],
      },
      zeroToHundred: [],
      editable: false,
      passOk: false,
      rules: {
        customPassMarks: [
          (v) => this.betweenValue(v, 0, 100, false, "Pass marks"),
        ],
      },
      formIssues: {},
    };
  },
  computed: {
    lowestHigh() {
      return this.formData.letter_grades[this.formData.letter_grades.length - 1]
        .highest_percentage;
    },
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    isOwner() {
      return this.$store.state.user.user.access[
        this.currentOrganization.partner_code
      ].includes("owner");
    },
    editing() {
      return this.editable && this.isOwner;
    },
    weightSumNotValid() {
      let val =
        Number(this.formData.grading_weights.assignment_weight) +
          Number(this.formData.grading_weights.project_weight) +
          Number(this.formData.grading_weights.exam_weight) !==
        100;
      return val;
    },
    hasFormIssues() {
      return Object.keys(this.formIssues).length > 0;
    },
    badFormMessage() {
      const errorArray = [];
      Object.values(this.formIssues).forEach((issue) => {
        errorArray.push(issue[this.$language]);
      });
      return errorArray.join(", ");
    },
  },
  watch: {
    radioValue(value) {
      if (value === 0) {
        this.formData.pass_mark = this.lowestHigh;
        this.updatePass();
      }
    },
    lowestHigh(value) {
      if (this.radioValue === 0) {
        this.formData.pass_mark = value;
        this.updatePass();
      }
    },
    weightSumNotValid(value) {
      if (!value) this.$delete(this.formIssues, "weightSum");
    },
    passOk(value) {
      if (value) this.$delete(this.formIssues, "passMark");
    },
    hasFormIssues(value) {
      if (!value) this.showAlertBanner = false;
    },
  },
  async created() {

    const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"]);
        return;
    }
    this.zeroToHundred = [...Array(101).keys()];
    await this.getGradingFormat();
    if (
      this.gradingFormat === null ||
      Object.keys(this.gradingFormat).length === 0
    ) {
      this.editable = true;
      // this.title = !this.$language ? "Add Grading" : "গ্রেডিং যোগ করুন";
      this.title = "Add Grading";
      this.banglaTitle = "গ্রেডিং যোগ করুন";
      this.radioValue = 0;
    } else {
      this.editable = false;
      // this.title = !this.$language ? "Grading" : "গ্রেডিং";
      this.title = "Grading";
      this.banglaTitle = "গ্রেডিং";
      this.radioValue = 1;
      this.formData = cloneDeep(this.gradingFormat);
      this.updatePass();
    }
    if (!this.isOwner) {
      //this.title = !this.$language ? "Grading" : "গ্রেডিং";
      this.title = "Grading";
      this.banglaTitle = "গ্রেডিং";
    }
    this.renderNow = true;
  },
  methods: {
    letterGradesOk() {
      let _zeroToHundred = cloneDeep(this.zeroToHundred);

      for (let i = 0; i < this.formData.letter_grades.length; i++) {
        let lowest_mark = this.formData.letter_grades[i]["lowest_percentage"];
        let highest_mark = this.formData.letter_grades[i]["highest_percentage"];

        for (let mark = lowest_mark; mark <= highest_mark; mark++) {
          if (!_zeroToHundred.includes(mark)) return false;
          this.array_remove_index_by_value(_zeroToHundred, mark);
        }
      }
      if (_zeroToHundred.length != 0) return false;
      else {
        this.$delete(this.formIssues, "letterGrade");
        return true;
      }
    },
    updatePass() {
      this.passOk =
        this.formData.pass_mark < 100 && this.formData.pass_mark > 0;
    },
    array_remove_index_by_value(arr, item) {
      for (let i = arr.length; i--; ) {
        if (arr[i] === item) {
          arr.splice(i, 1);
          return;
        }
      }
    },
    async setEditableTrue() {
      if (this.checkEditValidity()) {
        if (this.showAlertBanner) {
          const banner = this.$refs.banner;
          await banner.flash();
        } else {
          this.showAlertBanner = true;
        }
        return;
      }
      this.editable = true;
      this.title = !this.$language ? "Edit Grading" : "গ্রেডিং এডিট করুন";
    },
    setEditableFalse() {
      this.editable = false;
      this.title = "Grading";
      if (
        !(
          this.gradingFormat === null ||
          Object.keys(this.gradingFormat).length === 0
        )
      ) {
        this.formData = cloneDeep(this.gradingFormat);
      }
    },
    addAnotherGrade() {
      this.formData.letter_grades.push({
        lowest_percentage: undefined,
        grade_name: undefined,
        highest_percentage: undefined,
      });
    },
    async saveGradingFormat() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      if (this.checkSaveValidity()) {
        if (this.showAlertBanner) {
          const banner = this.$refs.banner;
          await banner.flash();
        } else {
          this.showAlertBanner = true;
        }
        return;
      }
      try {
        await gradingApi.save(this.formData);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
      this.editable = false;
      this.title = "Grading";
    },
    async getGradingFormat() {
      try {
        let response = await gradingApi.get({ course_id: this.courseId });
        this.gradingFormat = response.data.content.grading;
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e),
        ]);
      }
    },
    checkSaveValidity() {
      if (this.weightSumNotValid) {
        this.$set(this.formIssues, "weightSum", {
          true: "অ্যাসাইনমেন্ট, প্রজেক্ট এবং পরীক্ষার মার্কসের সমষ্টি ১০০ হতে হবে",
          false:
            "The summation of assignment, project and exam weight must be 100",
        });
      }
      if (!this.letterGradesOk()) {
        this.$set(this.formIssues, "letterGrade", {
          true: "লেটার গ্রেড অবশ্যই সমস্ত শতাংশ কভার করবে এবং ওভারল্যাপ করবে না",
          false:
            "The letter grades must cover all the percentages and must not overlap",
        });
      }
      if (!this.passOk) {
        this.$set(this.formIssues, "passMark", {
          true: "পাশ মার্ক অবসসই ০ থেকে ১০০ এর মধ্যে হতে হবে, (০ এঁবং ১০০ প্রজোয্য নয়)",
          false:
            "Pass marks must be between 0 and 100, (0 and 100) not allowed",
        });
      }
      return this.hasFormIssues;
    },
    checkEditValidity() {
      if (this.formData?.locked == true) {
        this.$set(this.formIssues, "editLocked", {
          true: "শিক্ষার্থী পরীক্ষা জমা দেয়ার পর গ্রেডিং ফরম্যাট পরিবর্তন করা নিষিদ্ধ",
          false:
            "Once a student has submitted editing grading format is disabled",
        });
      }
      return this.hasFormIssues;
    },
  },
};
</script>

<style scoped></style>
