var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "v-container",
        { staticClass: "mt-10" },
        [
          _c("PageTitle", {
            attrs: {
              "page-title": !this.$language ? _vm.title : _vm.banglaTitle,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "left",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "black", icon: "", large: "" },
                          on: {
                            click: () => {
                              _vm.$router.push({
                                name: "grading",
                              })
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("arrow_back")])],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1067328038
            ),
          }),
          _c(
            "div",
            { staticClass: "pt-2", staticStyle: { width: "1100px" } },
            [
              _c("submission-alert-message", {
                ref: "banner",
                attrs: {
                  "should-scroll-to-top": "",
                  id: "banner",
                  message: _vm.badFormMessage,
                },
                model: {
                  value: _vm.showAlertBanner,
                  callback: function ($$v) {
                    _vm.showAlertBanner = $$v
                  },
                  expression: "showAlertBanner",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "mt-10",
              attrs: { width: "1100", outlined: "", flat: "" },
            },
            [
              _c(
                "div",
                { staticClass: "ml-10 mt-10" },
                [
                  _c(
                    "v-row",
                    [
                      !_vm.$language
                        ? _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(
                              "Predefined data types are already added. Enter your weigh "
                            ),
                            _c("b", [_vm._v("Percentage")]),
                            _vm._v(" for each data type. "),
                          ])
                        : _c("v-col", { attrs: { cols: "9" } }, [
                            _vm._v(
                              "ইতিমধ্যে ডেটা টাইপ যোগ করা হয়েছে। প্রতিটি ডেটার জন্য শতকরা মান টাইপ করুন।"
                            ),
                          ]),
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _vm.isOwner && !_vm.editable
                          ? _c(
                              "div",
                              [
                                !_vm.$language
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          rounded: "",
                                          outlined: "",
                                          color: "primary",
                                          width: "160",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setEditableTrue()
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [_vm._v("edit")]),
                                        _vm._v(" Edit "),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          rounded: "",
                                          outlined: "",
                                          color: "primary",
                                          width: "160",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setEditableTrue()
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [_vm._v("edit")]),
                                        _vm._v(" এডিট "),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "ml-2 mt-4",
                      staticStyle: { width: "430px" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticStyle: { "font-weight": "bold" } },
                        [
                          !_vm.$language
                            ? _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("Type"),
                              ])
                            : _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("টাইপ"),
                              ]),
                          !_vm.$language
                            ? _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("Percentage (%)"),
                              ])
                            : _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("শতকরা মান (%)"),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4",
                          staticStyle: { "border-bottom": "1px solid #d0d0d0" },
                        },
                        [
                          !_vm.$language
                            ? _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("Assignments"),
                              ])
                            : _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("অ্যাসাইনমেন্ট"),
                              ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  placeholder: "Enter text here...",
                                  disabled: !_vm.editing,
                                },
                                model: {
                                  value:
                                    _vm.formData.grading_weights
                                      .assignment_weight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData.grading_weights,
                                      "assignment_weight",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.grading_weights.assignment_weight",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4",
                          staticStyle: { "border-bottom": "1px solid #d0d0d0" },
                        },
                        [
                          !_vm.$language
                            ? _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("Project"),
                              ])
                            : _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("প্রজেক্ট"),
                              ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  placeholder: "Enter text here...",
                                  disabled: !_vm.editing,
                                },
                                model: {
                                  value:
                                    _vm.formData.grading_weights.project_weight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData.grading_weights,
                                      "project_weight",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.grading_weights.project_weight",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4",
                          staticStyle: { "border-bottom": "1px solid #d0d0d0" },
                        },
                        [
                          !_vm.$language
                            ? _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("Exams"),
                              ])
                            : _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v("পরীক্ষা"),
                              ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  placeholder: "Enter text here...",
                                  disabled: !_vm.editing,
                                },
                                model: {
                                  value:
                                    _vm.formData.grading_weights.exam_weight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData.grading_weights,
                                      "exam_weight",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.grading_weights.exam_weight",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.editing && _vm.weightSumNotValid
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                color: "red",
                                "font-size": "14px",
                              },
                            },
                            [
                              !_vm.$language
                                ? _c("span", [
                                    _vm._v(
                                      "The summation of assignment, project and exam weight must be 100."
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "অ্যাসাইনমেন্ট, প্রজেক্ট এবং পরীক্ষার মার্কসের সমষ্টি ১০০ হতে হবে।"
                                    ),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "ml-10 mt-10" }, [
                !_vm.$language
                  ? _c("h3", [_vm._v("Letter Grading:")])
                  : _c("h3", [_vm._v("লেটার গ্রেডিং:")]),
                !_vm.$language
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.editing
                              ? `Add Letter Grading ( for example A+ , A , A- etc.) for the final grades of the students`
                              : `This grading will be added based on final marks.`
                          ) +
                          " "
                      ),
                    ])
                  : _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.editing
                              ? `শিক্ষার্থীদের চূড়ান্ত গ্রেডের জন্য লেটার গ্রেডিং যোগ করুন (উদাহরণস্বরূপ A+, A, A- ইত্যাদি)`
                              : `চূড়ান্ত নম্বরের ভিত্তিতে এই গ্রেডিং যোগ করা হবে।`
                          ) +
                          " "
                      ),
                    ]),
                _vm.editing && !_vm.letterGradesOk()
                  ? _c(
                      "span",
                      { staticStyle: { color: "red", "font-size": "14px" } },
                      [
                        !_vm.$language
                          ? _c("span", [
                              _vm._v(
                                " The letter grades must cover all the percentages and must not overlap."
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "লেটার গ্রেড অবশ্যই সমস্ত শতাংশ কভার করবে এবং ওভারল্যাপ করবে না।"
                              ),
                            ]),
                      ]
                    )
                  : _vm._e(),
                !_vm.showLetterGrading
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              outlined: "",
                              color: "primary",
                              rounded: "",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showLetterGrading = true
                              },
                            },
                          },
                          [_vm._v("Add Letter Grading")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showLetterGrading
                  ? _c(
                      "div",
                      {
                        staticClass: "ml-2 mt-4",
                        staticStyle: { width: "500px" },
                      },
                      [
                        _c(
                          "v-row",
                          { staticStyle: { "font-weight": "bold" } },
                          [
                            !_vm.$language
                              ? _c("v-col", { attrs: { cols: "5" } }, [
                                  _vm._v("Grade"),
                                ])
                              : _c("v-col", { attrs: { cols: "5" } }, [
                                  _vm._v("গ্রেড"),
                                ]),
                            _c("v-col", { attrs: { cols: "2" } }),
                            !_vm.$language
                              ? _c(
                                  "v-col",
                                  {
                                    staticStyle: { "text-align": "left" },
                                    attrs: { cols: "5" },
                                  },
                                  [_vm._v("Limit (%) (lower to upper)")]
                                )
                              : _c(
                                  "v-col",
                                  {
                                    staticStyle: { "text-align": "left" },
                                    attrs: { cols: "5" },
                                  },
                                  [_vm._v("লিমিট (%) (নিম্ন থেকে উচ্চ)")]
                                ),
                          ],
                          1
                        ),
                        _vm._l(_vm.formData.letter_grades, function (grade, i) {
                          return _c(
                            "v-row",
                            {
                              key: i,
                              staticClass: "mt-4",
                              staticStyle: {
                                "border-bottom": "1px solid #d0d0d0",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      placeholder: !_vm.$language
                                        ? "Enter grade here..."
                                        : "এখানে গ্রেড লিখুন",
                                      disabled: !_vm.editing,
                                    },
                                    model: {
                                      value:
                                        _vm.formData.letter_grades[i]
                                          .grade_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.letter_grades[i],
                                          "grade_name",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.letter_grades[i].grade_name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "2" } }),
                              _c(
                                "v-col",
                                { attrs: { cols: "5" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-select", {
                                        staticStyle: {
                                          width: "20px !important",
                                        },
                                        attrs: {
                                          items: _vm.zeroToHundred,
                                          outlined: "",
                                          placeholder: "00",
                                          disabled: !_vm.editing,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.letterGradesOk()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.formData.letter_grades[i]
                                              .lowest_percentage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.letter_grades[i],
                                              "lowest_percentage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.letter_grades[i].lowest_percentage",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "pa-4",
                                          staticStyle: {
                                            "align-content": "center",
                                          },
                                        },
                                        [_vm._v("to")]
                                      ),
                                      _c("v-select", {
                                        staticStyle: {
                                          width: "20px !important",
                                        },
                                        attrs: {
                                          items: _vm.zeroToHundred,
                                          outlined: "",
                                          placeholder: "00",
                                          disabled: !_vm.editing,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.letterGradesOk()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.formData.letter_grades[i]
                                              .highest_percentage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.letter_grades[i],
                                              "highest_percentage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.letter_grades[i].highest_percentage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "1" } },
                                [
                                  _vm.editing
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", color: "#FF5252" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.formData.letter_grades.splice(
                                                i,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("close")])],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _c(
                          "v-col",
                          [
                            _vm.editing
                              ? _c(
                                  "v-btn",
                                  {
                                    staticStyle: { "text-transform": "none" },
                                    attrs: {
                                      rounded: "",
                                      text: "",
                                      color: "primary",
                                    },
                                    on: { click: _vm.addAnotherGrade },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("mdi-plus"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? "Add another"
                                            : "আরেকটি যোগ করুন"
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "ml-10 mt-10" },
                [
                  !_vm.$language
                    ? _c("h3", [_vm._v("Pass Mark")])
                    : _c("h3", [_vm._v("পাশ মার্ক")]),
                  !_vm.editing
                    ? _c("p", [
                        _vm._v(_vm._s(_vm.formData.pass_mark || "Not set yet")),
                      ])
                    : _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.radioValue,
                            callback: function ($$v) {
                              _vm.radioValue = $$v
                            },
                            expression: "radioValue",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              value: 0,
                              label: !_vm.$language
                                ? "Use lowest range as pass mark (" +
                                  _vm.lowestHigh +
                                  ")"
                                : "পাস মার্ক হিসাবে সর্বনিম্ন পরিসর ব্যবহার করুন (" +
                                  _vm.lowestHigh +
                                  ")",
                            },
                          }),
                          !_vm.$language
                            ? _c("v-radio", {
                                attrs: { value: 1, label: "Custom" },
                              })
                            : _c("v-radio", {
                                attrs: { value: 1, label: "কাস্টম" },
                              }),
                        ],
                        1
                      ),
                  _vm.radioValue === 1 && _vm.editing
                    ? _c("v-text-field", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          outlined: "",
                          label: !_vm.$language
                            ? "Custom pass mark"
                            : "কাস্টম পাশ মার্ক",
                          rules: _vm.rules.customPassMarks,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updatePass()
                          },
                        },
                        model: {
                          value: _vm.formData.pass_mark,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "pass_mark", $$v)
                          },
                          expression: "formData.pass_mark",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.editing
                ? _c(
                    "v-row",
                    { staticClass: "mt-4 mr-4" },
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                rounded: "",
                                outlined: "",
                                color: "red accent-3",
                                width: "143",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setEditableFalse()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("cancel"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language ? "Cancel" : " বাতিল করুন"
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                rounded: "",
                                depressed: "",
                                color: "primary",
                                width: "143",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveGradingFormat()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("save_alt"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(!_vm.$language ? "Save" : "সেভ করুন") +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          [
            _c("div", { attrs: { id: "loading-wrapper" } }, [
              _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
              _c("div", { attrs: { id: "loading-content" } }),
            ]),
          ],
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }